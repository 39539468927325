<template>
  <div class="searchbar">
    <div class="searchbar__primary-icon">
      <slot>
        <ImagesSvg
          width="24"
          height="24"
          :icon="primaryIcon"
          :label-visible="false"
          class="d-block" />
      </slot>
    </div>
    <div class="searchbar__secondary-icon">
      <IconButton
        v-if="secondaryIcon"
        :icon="secondaryIcon"
        :label="secondaryIcon"
        @click="$router.push(routes.scanner.path)" />
    </div>
    <input
      ref="searchBarEl"
      type="text"
      class="searchbar__box"
      :class="[{ shadow: hasShadow }, backgroundColor]"
      :placeholder="placeholderText"
      :value="modelValue"
      @focus="initSearch"
      @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)" />
  </div>
</template>

<script lang="ts" setup>
import type { SearchbarBgColor } from '@/types/color';
import type { Icon } from '@/types/icon';
import IconButton from '@/components/buttons/IconButton.vue';

interface SearchbarProps {
  placeholderText?: string;
  primaryIcon?: Icon;
  secondaryIcon?: Icon | null;
  backgroundColor?: SearchbarBgColor;
  hasShadow?: boolean;
  hasFocus?: boolean;
  isLocationsSearch?: boolean;
  modelValue: string;
}

const props = withDefaults(defineProps<SearchbarProps>(), {
  placeholderText: 'Zoek een product of merk',
  primaryIcon: 'search',
  secondaryIcon: null,
  backgroundColor: 'white',
  hasShadow: false,
  hasFocus: false,
});

defineEmits<{
  (e: 'update:modelValue', searchQuery: string): void;
}>();

const searchBarEl = ref<HTMLInputElement | null>(null);

const route = useRoute();

onMounted(() => {
  if (props.hasFocus) {
    searchBarEl.value?.focus();
  }
});

function initSearch() {
  if (route.path === routes.search.path || props.isLocationsSearch) {
    return;
  }
  navigateTo(routes.search.path);
}
</script>

<style lang="scss" scoped>
.searchbar {
  position: relative;

  &__box {
    display: block;
    width: 100%;
    padding: 0 px(53);
    border: 0;
    border-radius: px(8);
    background-color: $c-white;
    height: px(48);

    &.white {
      background-color: $c-white;
    }

    &.gray {
      background-color: $c-neutral-2;
    }

    &.shadow {
      box-shadow: 0 0 px(8) rgba(0, 104, 75, 0.15);
    }

    &:focus {
      outline: none;
    }
  }

  @mixin icon-boxes {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
  }

  &__primary-icon {
    @include icon-boxes;
    left: px(22);
  }

  &__secondary-icon {
    @include icon-boxes;
    right: px(18);

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
